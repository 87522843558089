@import '../../assets/scss/auth';

.forgot-password{
  height: 60vh;
  p{
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    width: 40%;
    margin: auto;
  }

  .form-group{
    width: 45%;;
  }
}

@media screen and (max-width: 768px) {
  .forgot-password{
    h1{
      margin-top: 1rem;
    }
    p{
      font-size: 20px;
      line-height: 33px;
      text-align: center;
      width: 100%;
      margin: auto;
    }

    .auth-form {
      .form-group {
        width: 100%;
        margin-bottom: 0;
      }

      .btn-primary{
        margin-top: 0 !important;
      }

      a {
        margin-top: 1rem;
      }
    }
  }

}
