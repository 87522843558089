@import '../../assets/scss/index';

.footer {
  background-color: #fff;
  border: 1px solid $primary-red;
  font-size: 18px;
  height: 88px;
  line-height: 18px;
  text-align: center;

  .link {
    color: $primary-black;
  }

  p {
    margin-bottom: 0;
  }

  .middle {
    font-size: 24px;
    font-weight: bold;
    line-height: 39px;
  }
}

@media screen and (max-width: 768px) {

  .footer {
    font-size: 10px;
    height: 50px;

    .middle {
      font-size: 10px;
      font-weight: bold;
    }
  }
}
