@import '../../assets/scss/index';

.jumbotron {
  background: linear-gradient(to bottom,  #E36C55 0%, #E36C55 40%, $primary-red 90%, $primary-white 0%);
  height: 514px;
  margin-bottom: 0 !important;

  .lead {
    font-size: 72px;
    font-weight: 900;
    margin: auto;
    width: 80%;
  }

  .sub-title{
    font-size: 24px;
  }

  .form-group {
    margin-bottom: 0 !important;

    .label {
      font-size: 24px;
      margin-bottom: 0 !important;
    }

    .search-input {
      border: 1px solid #d59152;
      border-radius: 50px;
      font-size: 24px;
      height: 90px;
      margin: 0 auto;
      text-align: center;
      width: 40%;

      &::placeholder {
        color: $primary-grey;
        font-size: 24px;
        text-align: center;
      }

      &:focus {
        box-shadow: 0 0 0 .1rem $primary-red;
      }
    }
  }
}

.about-section {
  font-family: $catamaran;
  font-size: 24px;
  line-height: 39px;
  padding: 87px 0 157px;

  .title {
    color: $primary-grey;
    font-size: 24px;
    font-weight: bold;
  }

  .body {
    color: $primary-black;
    font-weight: 500;
    margin: 105px auto 121px;
    width: 52%;
  }

  .link {
    border: 1.5px solid $primary-red;
    border-radius: 50px;
    box-sizing: border-box;
    color: $primary-red;
    padding: 13px 37px;

    &:hover {
      background: $primary-red;
      color: $primary-white;
      text-decoration: none;
    }
  }
}

.extras {
  font-family: $catamaran;
  margin-bottom: 50px;

  .title {
    color: $primary-black;
    font-size: 36px;
    font-weight: 600;
    line-height: 45px;
    width: 75%;
  }

  .body {
    color: $primary-black;
    font-size: 18px;
    line-height: 30px;
    width: 75%;
  }

  .top {
    margin-bottom: 50px;
  }


  .img {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {

  .jumbotron {
    height: 100%;

    .lead {
      font-size: 36px;
      font-weight: 600;
      margin: 2rem auto;
      width: 100%;
    }

    .form-group {

      .label {
        font-size: 16px;
      }

      .search-input {
        border: 1px solid #d59152;
        border-radius: 50px;
        font-size: 20px;
        height: 60px;
        margin: 0 auto;
        text-align: center;
        width: 100%;

        &::placeholder {
          color: $primary-grey;
          font-size: 20px;
          text-align: center;
        }
      }
    }
  }

  .about-section {
    font-size: 18px;
    line-height: 24px;
    padding: 30px 0 60px;

    .body {
      margin: 20px auto 40px;
      width: 100%;
    }

    .link {
      padding: 5px 40px;
    }
  }

  .extras {
    margin-bottom: 20px;

    .title {
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      width: 100%;
    }

    .body {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      width: 100%;
    }

    .top,
    .bottom {
      margin-bottom: 20px;
    }

    .img {
      //width: 400;
    }
  }
}

