@import './variables';

a,
p,
div,
body {
  font-family: $catamaran;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $lato;
}

body {
  font-size: 16px;
}

.btn-primary {
  color: #fff;
  background-color: $primary-red !important;
  border-color: transparent !important;
  border-radius: 4px;

  &:hover {
    background-color: #c34848 !important;
    transition: .3s ease-in-out;
  }

  &:disabled {
    background-color: $secondary-red !important;
  }
}

.red-border-bottom {
  border-bottom: $primary-red solid 1px;
}

@media screen and (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important;
  }
}
