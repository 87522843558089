@import '../../assets/scss/index';

.search-result{

  .result-container{
    width: 90%;
    margin: auto;

  }

  .footer{
    margin-top: 3rem !important;
  }
}
