@import './index';

$border-style: #a47575 solid 1.2px;

.auth-page{
  .auth-section {
    h1{
      font-family: $catamaran;
      font-size: 40px;
    }

    .auth-form {
      width: 50%;

      input{
        border: $border-style;
        border-radius: 4px;
        font-size: 20px;
        height: 56px;
        width: 100%;

        &::placeholder {
          color: $primary-grey;
        }
      }

      a, span{
        border-bottom: $border-style;
        color: #000;
        font-size: 20px;
      }
      span {
        border: 0;
      }

      a:hover, a:active{
        border-bottom: $primary-red solid 2px;
        text-decoration: unset;
      }
    }

    .btn-primary{
      background-color: $primary-red !important;
      border: 0 !important;
      font-size: 20px !important;
      min-height: 51px !important;
      min-width: 142px;
    }
  }

  .footer{
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
  }
}

@media screen and (max-width: 768px) {
  .auth-form {
    width: 100% !important;

    .btn-primary{
      margin-top: 3vh;
      width: 100% !important;
    }
  }
}
