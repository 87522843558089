@import '../../assets/scss/index';

.navbar {
  background-color: $primary-white !important;
  padding: 1rem 2rem !important;

  .navbar-brand {
    color: $primary-red !important;
    font-size: 4rem !important;
    font-weight: bolder;
    line-height: 4rem !important;
    padding: .2rem 1rem !important;
  }

  .header-links{
    a{
      color: #000  !important;
      font-size: 18px !important;
    }
  }

  .navbar-nav {

    .nav-item {

      .nav-link {
        background-color: $primary-red !important;
        border-radius: 50px;
        font-size: 18px;
        height: 60px;
        width: 200px;

        &:hover {
          background-color: $primary-grey !important;
          transition: .3s ease-in-out;
        }
      }
    }
  }

  .search-wrap {
    height: 45px;

    .search-input {
      border: solid 1px #eb5757;
      border-radius: 4px !important;
      height: 45px !important;
      width: 450px !important;
    }

    .search-icon {
      float: right;
      position: relative;
      right: 15px;
      top: -35px;
    }

    .search-button {
      height: 45px !important;
      width: 95px !important;
    }
  }

  .user-avatar {
    a {
      color: $primary-black;
      font-size: 18px;

      &:hover {
        color: $primary-black;
      }
    }
  }

  .show-on-mobile{
    display: none !important;
  }
}

@media screen and (max-width: 768px) {

  .navbar {
    padding: 0 !important;

    .navbar-brand {
      font-size: 2rem !important;
      padding-bottom: 0 !important;
      padding-top: 0 !important;
    }

    .navbar-nav {
      padding: .2rem 1rem;

      .nav-item {
        .nav-link {
          font-size: 12px;
          font-weight: bold;
          height: 45px;
          line-height: 10px;
          width: 130px;
        }
      }
    }

    .header-links{
      display: none !important;
    }

    .search-wrap {
      .search-input {
        border-radius: 4px 0 0 4px !important;
        width: 100% !important;
      }
      .search-icon {
        display: none;
      }
      .search-button {
        border-radius: 0 4px 4px 0 !important;
        margin-left: 0 !important;
        margin-top: 0 !important;
      }
    }

    .hide-on-mobile{
      display: none !important;
    }

    .show-on-mobile{
      display: block !important;
    }
  }

}

