@import '../../assets/scss/index';
.input-container, .textarea-container, .select-container {
  position: relative;
  margin-bottom: 1.5rem;

  label {
    position: absolute;
    top: 0;
    padding: 0 0 0 13px;
    color: #A69191;
  }

  input, textarea {

    &:placeholder-shown + label{
      opacity: 0;
    }

    &:placeholder-shown:focus + label{
      opacity: 1;
      transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out;
    }

  }

  input:placeholder-shown:focus, textarea:placeholder-shown:focus{
    &::-webkit-input-placeholder{
      color: transparent;
      transition: color 0.25s ease-in-out
    }
  }

  .required-indicator{
    position: absolute;
    right: -10px;
    top: 20px;
  }

}

.textarea-container {
  label {
    left: 0 !important;
  }
  textarea {
    padding-top: 15px;
  }
}
