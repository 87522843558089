@import '../../assets/scss/index';

$border-style: #a47575 solid 1.2px;

.submit-review {
  h1{
    font-family: $catamaran;
    font-size: 40px;
    margin-top: 5rem !important;
  }

  form {
    width: 50%;

    input, textarea, select{
      border: $border-style;
      border-radius: 4px;
      font-size: 20px;
      height: 56px;
      width: 100%;

      &::placeholder {
        color: $primary-grey;
      }
    }

    select {
      background-color: transparent;
    }

    select option[data-default] {
      color: #a69191 !important;
    }

    textarea{
      height: 263px !important;
    }

    a, span{
      border-bottom: $border-style;
      color: #000;
      font-size: 20px;
    }
    span {
      border: 0;
    }

    a:hover, a:active{
      border-bottom: $primary-red solid 2px;
      text-decoration: unset;
    }
  }

  .step-indicator{

    width: 50%;

    hr{
      border: solid 1px #8d8d8d !important;
      width: 80% !important;
    }

    .step{
      background-color: transparent;
      border: solid 3px #d2c8c8;
      border-radius: 50%;
      color: #d2c8c8;
      font-size: 22px;
      height: 60px;
      width: 60px;
    }

    .current{
      border: solid 3px #7d3b3b;
      color: #000;
    }
  }

  .btn-primary{
    height: 45px;
    width: 137px;
  }
}

@media screen and (max-width: 768px) {
  .submit-review {
    width: 100% !important;

    .step-indicator {
      width: 100%;
      hr{
        width: 75% !important;
      }
    }

    .btn-primary{
      margin-top: 3vh;
      width: initial !important;
    }
  }
}
