@import url(https://fonts.googleapis.com/css?family=Catamaran:400,700|Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Catamaran:400,700|Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Catamaran:400,700|Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Catamaran:400,700|Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Catamaran:400,700|Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Catamaran:400,700|Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Catamaran:400,700|Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Catamaran:400,700|Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Catamaran:400,700|Lato:400,700,900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Catamaran:400,700|Lato:400,700,900&display=swap);
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a,
p,
div,
body {
  font-family: "Catamaran", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif; }

body {
  font-size: 16px; }

.btn-primary {
  color: #fff;
  background-color: #eb5757 !important;
  border-color: transparent !important;
  border-radius: 4px; }
  .btn-primary:hover {
    background-color: #c34848 !important;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out; }
  .btn-primary:disabled {
    background-color: #a17272 !important; }

.red-border-bottom {
  border-bottom: #eb5757 solid 1px; }

@media screen and (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important; } }

.navbar {
  background-color: #fff !important;
  padding: 1rem 2rem !important; }
  .navbar .navbar-brand {
    color: #eb5757 !important;
    font-size: 4rem !important;
    font-weight: bolder;
    line-height: 4rem !important;
    padding: .2rem 1rem !important; }
  .navbar .header-links a {
    color: #000  !important;
    font-size: 18px !important; }
  .navbar .navbar-nav .nav-item .nav-link {
    background-color: #eb5757 !important;
    border-radius: 50px;
    font-size: 18px;
    height: 60px;
    width: 200px; }
    .navbar .navbar-nav .nav-item .nav-link:hover {
      background-color: #c4c4c4 !important;
      -webkit-transition: .3s ease-in-out;
      transition: .3s ease-in-out; }
  .navbar .search-wrap {
    height: 45px; }
    .navbar .search-wrap .search-input {
      border: solid 1px #eb5757;
      border-radius: 4px !important;
      height: 45px !important;
      width: 450px !important; }
    .navbar .search-wrap .search-icon {
      float: right;
      position: relative;
      right: 15px;
      top: -35px; }
    .navbar .search-wrap .search-button {
      height: 45px !important;
      width: 95px !important; }
  .navbar .user-avatar a {
    color: #000;
    font-size: 18px; }
    .navbar .user-avatar a:hover {
      color: #000; }
  .navbar .show-on-mobile {
    display: none !important; }

@media screen and (max-width: 768px) {
  .navbar {
    padding: 0 !important; }
    .navbar .navbar-brand {
      font-size: 2rem !important;
      padding-bottom: 0 !important;
      padding-top: 0 !important; }
    .navbar .navbar-nav {
      padding: .2rem 1rem; }
      .navbar .navbar-nav .nav-item .nav-link {
        font-size: 12px;
        font-weight: bold;
        height: 45px;
        line-height: 10px;
        width: 130px; }
    .navbar .header-links {
      display: none !important; }
    .navbar .search-wrap .search-input {
      border-radius: 4px 0 0 4px !important;
      width: 100% !important; }
    .navbar .search-wrap .search-icon {
      display: none; }
    .navbar .search-wrap .search-button {
      border-radius: 0 4px 4px 0 !important;
      margin-left: 0 !important;
      margin-top: 0 !important; }
    .navbar .hide-on-mobile {
      display: none !important; }
    .navbar .show-on-mobile {
      display: block !important; } }

a,
p,
div,
body {
  font-family: "Catamaran", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif; }

body {
  font-size: 16px; }

.btn-primary {
  color: #fff;
  background-color: #eb5757 !important;
  border-color: transparent !important;
  border-radius: 4px; }
  .btn-primary:hover {
    background-color: #c34848 !important;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out; }
  .btn-primary:disabled {
    background-color: #a17272 !important; }

.red-border-bottom {
  border-bottom: #eb5757 solid 1px; }

@media screen and (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important; } }

.input-container, .textarea-container, .select-container {
  position: relative;
  margin-bottom: 1.5rem; }
  .input-container label, .textarea-container label, .select-container label {
    position: absolute;
    top: 0;
    padding: 0 0 0 13px;
    color: #A69191; }
  .input-container input:placeholder-shown + label, .input-container textarea:placeholder-shown + label, .textarea-container input:placeholder-shown + label, .textarea-container textarea:placeholder-shown + label, .select-container input:placeholder-shown + label, .select-container textarea:placeholder-shown + label {
    opacity: 0; }
  .input-container input:placeholder-shown:focus + label, .input-container textarea:placeholder-shown:focus + label, .textarea-container input:placeholder-shown:focus + label, .textarea-container textarea:placeholder-shown:focus + label, .select-container input:placeholder-shown:focus + label, .select-container textarea:placeholder-shown:focus + label {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out, color 0.25s ease-in-out; }
  .input-container input:placeholder-shown:focus::-webkit-input-placeholder, .input-container textarea:placeholder-shown:focus::-webkit-input-placeholder, .textarea-container input:placeholder-shown:focus::-webkit-input-placeholder, .textarea-container textarea:placeholder-shown:focus::-webkit-input-placeholder, .select-container input:placeholder-shown:focus::-webkit-input-placeholder, .select-container textarea:placeholder-shown:focus::-webkit-input-placeholder {
    color: transparent;
    -webkit-transition: color 0.25s ease-in-out;
    transition: color 0.25s ease-in-out; }
  .input-container .required-indicator, .textarea-container .required-indicator, .select-container .required-indicator {
    position: absolute;
    right: -10px;
    top: 20px; }

.textarea-container label {
  left: 0 !important; }

.textarea-container textarea {
  padding-top: 15px; }

a,
p,
div,
body {
  font-family: "Catamaran", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif; }

body {
  font-size: 16px; }

.btn-primary {
  color: #fff;
  background-color: #eb5757 !important;
  border-color: transparent !important;
  border-radius: 4px; }
  .btn-primary:hover {
    background-color: #c34848 !important;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out; }
  .btn-primary:disabled {
    background-color: #a17272 !important; }

.red-border-bottom {
  border-bottom: #eb5757 solid 1px; }

@media screen and (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important; } }

.jumbotron {
  background: -webkit-gradient(linear, left top, left bottom, from(#E36C55), color-stop(40%, #E36C55), color-stop(90%, #eb5757), color-stop(0%, #fff));
  background: linear-gradient(to bottom, #E36C55 0%, #E36C55 40%, #eb5757 90%, #fff 0%);
  height: 514px;
  margin-bottom: 0 !important; }
  .jumbotron .lead {
    font-size: 72px;
    font-weight: 900;
    margin: auto;
    width: 80%; }
  .jumbotron .sub-title {
    font-size: 24px; }
  .jumbotron .form-group {
    margin-bottom: 0 !important; }
    .jumbotron .form-group .label {
      font-size: 24px;
      margin-bottom: 0 !important; }
    .jumbotron .form-group .search-input {
      border: 1px solid #d59152;
      border-radius: 50px;
      font-size: 24px;
      height: 90px;
      margin: 0 auto;
      text-align: center;
      width: 40%; }
      .jumbotron .form-group .search-input::-webkit-input-placeholder {
        color: #c4c4c4;
        font-size: 24px;
        text-align: center; }
      .jumbotron .form-group .search-input::-moz-placeholder {
        color: #c4c4c4;
        font-size: 24px;
        text-align: center; }
      .jumbotron .form-group .search-input:-ms-input-placeholder {
        color: #c4c4c4;
        font-size: 24px;
        text-align: center; }
      .jumbotron .form-group .search-input::-ms-input-placeholder {
        color: #c4c4c4;
        font-size: 24px;
        text-align: center; }
      .jumbotron .form-group .search-input::placeholder {
        color: #c4c4c4;
        font-size: 24px;
        text-align: center; }
      .jumbotron .form-group .search-input:focus {
        box-shadow: 0 0 0 0.1rem #eb5757; }

.about-section {
  font-family: "Catamaran", sans-serif;
  font-size: 24px;
  line-height: 39px;
  padding: 87px 0 157px; }
  .about-section .title {
    color: #c4c4c4;
    font-size: 24px;
    font-weight: bold; }
  .about-section .body {
    color: #000;
    font-weight: 500;
    margin: 105px auto 121px;
    width: 52%; }
  .about-section .link {
    border: 1.5px solid #eb5757;
    border-radius: 50px;
    box-sizing: border-box;
    color: #eb5757;
    padding: 13px 37px; }
    .about-section .link:hover {
      background: #eb5757;
      color: #fff;
      text-decoration: none; }

.extras {
  font-family: "Catamaran", sans-serif;
  margin-bottom: 50px; }
  .extras .title {
    color: #000;
    font-size: 36px;
    font-weight: 600;
    line-height: 45px;
    width: 75%; }
  .extras .body {
    color: #000;
    font-size: 18px;
    line-height: 30px;
    width: 75%; }
  .extras .top {
    margin-bottom: 50px; }
  .extras .img {
    width: 100%; }

@media screen and (max-width: 768px) {
  .jumbotron {
    height: 100%; }
    .jumbotron .lead {
      font-size: 36px;
      font-weight: 600;
      margin: 2rem auto;
      width: 100%; }
    .jumbotron .form-group .label {
      font-size: 16px; }
    .jumbotron .form-group .search-input {
      border: 1px solid #d59152;
      border-radius: 50px;
      font-size: 20px;
      height: 60px;
      margin: 0 auto;
      text-align: center;
      width: 100%; }
      .jumbotron .form-group .search-input::-webkit-input-placeholder {
        color: #c4c4c4;
        font-size: 20px;
        text-align: center; }
      .jumbotron .form-group .search-input::-moz-placeholder {
        color: #c4c4c4;
        font-size: 20px;
        text-align: center; }
      .jumbotron .form-group .search-input:-ms-input-placeholder {
        color: #c4c4c4;
        font-size: 20px;
        text-align: center; }
      .jumbotron .form-group .search-input::-ms-input-placeholder {
        color: #c4c4c4;
        font-size: 20px;
        text-align: center; }
      .jumbotron .form-group .search-input::placeholder {
        color: #c4c4c4;
        font-size: 20px;
        text-align: center; }
  .about-section {
    font-size: 18px;
    line-height: 24px;
    padding: 30px 0 60px; }
    .about-section .body {
      margin: 20px auto 40px;
      width: 100%; }
    .about-section .link {
      padding: 5px 40px; }
  .extras {
    margin-bottom: 20px; }
    .extras .title {
      font-size: 24px;
      line-height: 30px;
      text-align: center;
      width: 100%; }
    .extras .body {
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      width: 100%; }
    .extras .top,
    .extras .bottom {
      margin-bottom: 20px; } }

a,
p,
div,
body {
  font-family: "Catamaran", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif; }

body {
  font-size: 16px; }

.btn-primary {
  color: #fff;
  background-color: #eb5757 !important;
  border-color: transparent !important;
  border-radius: 4px; }
  .btn-primary:hover {
    background-color: #c34848 !important;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out; }
  .btn-primary:disabled {
    background-color: #a17272 !important; }

.red-border-bottom {
  border-bottom: #eb5757 solid 1px; }

@media screen and (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important; } }

.auth-page .auth-section h1 {
  font-family: "Catamaran", sans-serif;
  font-size: 40px; }

.auth-page .auth-section .auth-form {
  width: 50%; }
  .auth-page .auth-section .auth-form input {
    border: #a47575 solid 1.2px;
    border-radius: 4px;
    font-size: 20px;
    height: 56px;
    width: 100%; }
    .auth-page .auth-section .auth-form input::-webkit-input-placeholder {
      color: #c4c4c4; }
    .auth-page .auth-section .auth-form input::-moz-placeholder {
      color: #c4c4c4; }
    .auth-page .auth-section .auth-form input:-ms-input-placeholder {
      color: #c4c4c4; }
    .auth-page .auth-section .auth-form input::-ms-input-placeholder {
      color: #c4c4c4; }
    .auth-page .auth-section .auth-form input::placeholder {
      color: #c4c4c4; }
  .auth-page .auth-section .auth-form a, .auth-page .auth-section .auth-form span {
    border-bottom: #a47575 solid 1.2px;
    color: #000;
    font-size: 20px; }
  .auth-page .auth-section .auth-form span {
    border: 0; }
  .auth-page .auth-section .auth-form a:hover, .auth-page .auth-section .auth-form a:active {
    border-bottom: #eb5757 solid 2px;
    text-decoration: unset; }

.auth-page .auth-section .btn-primary {
  background-color: #eb5757 !important;
  border: 0 !important;
  font-size: 20px !important;
  min-height: 51px !important;
  min-width: 142px; }

.auth-page .footer {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0; }

@media screen and (max-width: 768px) {
  .auth-form {
    width: 100% !important; }
    .auth-form .btn-primary {
      margin-top: 3vh;
      width: 100% !important; } }

a,
p,
div,
body {
  font-family: "Catamaran", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif; }

body {
  font-size: 16px; }

.btn-primary {
  color: #fff;
  background-color: #eb5757 !important;
  border-color: transparent !important;
  border-radius: 4px; }
  .btn-primary:hover {
    background-color: #c34848 !important;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out; }
  .btn-primary:disabled {
    background-color: #a17272 !important; }

.red-border-bottom {
  border-bottom: #eb5757 solid 1px; }

@media screen and (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important; } }

.auth-page .auth-section h1 {
  font-family: "Catamaran", sans-serif;
  font-size: 40px; }

.auth-page .auth-section .auth-form {
  width: 50%; }
  .auth-page .auth-section .auth-form input {
    border: #a47575 solid 1.2px;
    border-radius: 4px;
    font-size: 20px;
    height: 56px;
    width: 100%; }
    .auth-page .auth-section .auth-form input::-webkit-input-placeholder {
      color: #c4c4c4; }
    .auth-page .auth-section .auth-form input::-moz-placeholder {
      color: #c4c4c4; }
    .auth-page .auth-section .auth-form input:-ms-input-placeholder {
      color: #c4c4c4; }
    .auth-page .auth-section .auth-form input::-ms-input-placeholder {
      color: #c4c4c4; }
    .auth-page .auth-section .auth-form input::placeholder {
      color: #c4c4c4; }
  .auth-page .auth-section .auth-form a, .auth-page .auth-section .auth-form span {
    border-bottom: #a47575 solid 1.2px;
    color: #000;
    font-size: 20px; }
  .auth-page .auth-section .auth-form span {
    border: 0; }
  .auth-page .auth-section .auth-form a:hover, .auth-page .auth-section .auth-form a:active {
    border-bottom: #eb5757 solid 2px;
    text-decoration: unset; }

.auth-page .auth-section .btn-primary {
  background-color: #eb5757 !important;
  border: 0 !important;
  font-size: 20px !important;
  min-height: 51px !important;
  min-width: 142px; }

.auth-page .footer {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0; }

@media screen and (max-width: 768px) {
  .auth-form {
    width: 100% !important; }
    .auth-form .btn-primary {
      margin-top: 3vh;
      width: 100% !important; } }

a,
p,
div,
body {
  font-family: "Catamaran", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif; }

body {
  font-size: 16px; }

.btn-primary {
  color: #fff;
  background-color: #eb5757 !important;
  border-color: transparent !important;
  border-radius: 4px; }
  .btn-primary:hover {
    background-color: #c34848 !important;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out; }
  .btn-primary:disabled {
    background-color: #a17272 !important; }

.red-border-bottom {
  border-bottom: #eb5757 solid 1px; }

@media screen and (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important; } }

.submit-review h1 {
  font-family: "Catamaran", sans-serif;
  font-size: 40px;
  margin-top: 5rem !important; }

.submit-review form {
  width: 50%; }
  .submit-review form input, .submit-review form textarea, .submit-review form select {
    border: #a47575 solid 1.2px;
    border-radius: 4px;
    font-size: 20px;
    height: 56px;
    width: 100%; }
    .submit-review form input::-webkit-input-placeholder, .submit-review form textarea::-webkit-input-placeholder, .submit-review form select::-webkit-input-placeholder {
      color: #c4c4c4; }
    .submit-review form input::-moz-placeholder, .submit-review form textarea::-moz-placeholder, .submit-review form select::-moz-placeholder {
      color: #c4c4c4; }
    .submit-review form input:-ms-input-placeholder, .submit-review form textarea:-ms-input-placeholder, .submit-review form select:-ms-input-placeholder {
      color: #c4c4c4; }
    .submit-review form input::-ms-input-placeholder, .submit-review form textarea::-ms-input-placeholder, .submit-review form select::-ms-input-placeholder {
      color: #c4c4c4; }
    .submit-review form input::placeholder, .submit-review form textarea::placeholder, .submit-review form select::placeholder {
      color: #c4c4c4; }
  .submit-review form select {
    background-color: transparent; }
  .submit-review form select option[data-default] {
    color: #a69191 !important; }
  .submit-review form textarea {
    height: 263px !important; }
  .submit-review form a, .submit-review form span {
    border-bottom: #a47575 solid 1.2px;
    color: #000;
    font-size: 20px; }
  .submit-review form span {
    border: 0; }
  .submit-review form a:hover, .submit-review form a:active {
    border-bottom: #eb5757 solid 2px;
    text-decoration: unset; }

.submit-review .step-indicator {
  width: 50%; }
  .submit-review .step-indicator hr {
    border: solid 1px #8d8d8d !important;
    width: 80% !important; }
  .submit-review .step-indicator .step {
    background-color: transparent;
    border: solid 3px #d2c8c8;
    border-radius: 50%;
    color: #d2c8c8;
    font-size: 22px;
    height: 60px;
    width: 60px; }
  .submit-review .step-indicator .current {
    border: solid 3px #7d3b3b;
    color: #000; }

.submit-review .btn-primary {
  height: 45px;
  width: 137px; }

@media screen and (max-width: 768px) {
  .submit-review {
    width: 100% !important; }
    .submit-review .step-indicator {
      width: 100%; }
      .submit-review .step-indicator hr {
        width: 75% !important; }
    .submit-review .btn-primary {
      margin-top: 3vh;
      width: auto !important;
      width: initial !important; } }

a,
p,
div,
body {
  font-family: "Catamaran", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif; }

body {
  font-size: 16px; }

.btn-primary {
  color: #fff;
  background-color: #eb5757 !important;
  border-color: transparent !important;
  border-radius: 4px; }
  .btn-primary:hover {
    background-color: #c34848 !important;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out; }
  .btn-primary:disabled {
    background-color: #a17272 !important; }

.red-border-bottom {
  border-bottom: #eb5757 solid 1px; }

@media screen and (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important; } }

.auth-page .auth-section h1 {
  font-family: "Catamaran", sans-serif;
  font-size: 40px; }

.auth-page .auth-section .auth-form {
  width: 50%; }
  .auth-page .auth-section .auth-form input {
    border: #a47575 solid 1.2px;
    border-radius: 4px;
    font-size: 20px;
    height: 56px;
    width: 100%; }
    .auth-page .auth-section .auth-form input::-webkit-input-placeholder {
      color: #c4c4c4; }
    .auth-page .auth-section .auth-form input::-moz-placeholder {
      color: #c4c4c4; }
    .auth-page .auth-section .auth-form input:-ms-input-placeholder {
      color: #c4c4c4; }
    .auth-page .auth-section .auth-form input::-ms-input-placeholder {
      color: #c4c4c4; }
    .auth-page .auth-section .auth-form input::placeholder {
      color: #c4c4c4; }
  .auth-page .auth-section .auth-form a, .auth-page .auth-section .auth-form span {
    border-bottom: #a47575 solid 1.2px;
    color: #000;
    font-size: 20px; }
  .auth-page .auth-section .auth-form span {
    border: 0; }
  .auth-page .auth-section .auth-form a:hover, .auth-page .auth-section .auth-form a:active {
    border-bottom: #eb5757 solid 2px;
    text-decoration: unset; }

.auth-page .auth-section .btn-primary {
  background-color: #eb5757 !important;
  border: 0 !important;
  font-size: 20px !important;
  min-height: 51px !important;
  min-width: 142px; }

.auth-page .footer {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0; }

@media screen and (max-width: 768px) {
  .auth-form {
    width: 100% !important; }
    .auth-form .btn-primary {
      margin-top: 3vh;
      width: 100% !important; } }

.forgot-password {
  height: 60vh; }
  .forgot-password p {
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    width: 40%;
    margin: auto; }
  .forgot-password .form-group {
    width: 45%; }

@media screen and (max-width: 768px) {
  .forgot-password h1 {
    margin-top: 1rem; }
  .forgot-password p {
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    width: 100%;
    margin: auto; }
  .forgot-password .auth-form .form-group {
    width: 100%;
    margin-bottom: 0; }
  .forgot-password .auth-form .btn-primary {
    margin-top: 0 !important; }
  .forgot-password .auth-form a {
    margin-top: 1rem; } }

a,
p,
div,
body {
  font-family: "Catamaran", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif; }

body {
  font-size: 16px; }

.btn-primary {
  color: #fff;
  background-color: #eb5757 !important;
  border-color: transparent !important;
  border-radius: 4px; }
  .btn-primary:hover {
    background-color: #c34848 !important;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out; }
  .btn-primary:disabled {
    background-color: #a17272 !important; }

.red-border-bottom {
  border-bottom: #eb5757 solid 1px; }

@media screen and (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important; } }

.auth-page .auth-section h1 {
  font-family: "Catamaran", sans-serif;
  font-size: 40px; }

.auth-page .auth-section .auth-form {
  width: 50%; }
  .auth-page .auth-section .auth-form input {
    border: #a47575 solid 1.2px;
    border-radius: 4px;
    font-size: 20px;
    height: 56px;
    width: 100%; }
    .auth-page .auth-section .auth-form input::-webkit-input-placeholder {
      color: #c4c4c4; }
    .auth-page .auth-section .auth-form input::-moz-placeholder {
      color: #c4c4c4; }
    .auth-page .auth-section .auth-form input:-ms-input-placeholder {
      color: #c4c4c4; }
    .auth-page .auth-section .auth-form input::-ms-input-placeholder {
      color: #c4c4c4; }
    .auth-page .auth-section .auth-form input::placeholder {
      color: #c4c4c4; }
  .auth-page .auth-section .auth-form a, .auth-page .auth-section .auth-form span {
    border-bottom: #a47575 solid 1.2px;
    color: #000;
    font-size: 20px; }
  .auth-page .auth-section .auth-form span {
    border: 0; }
  .auth-page .auth-section .auth-form a:hover, .auth-page .auth-section .auth-form a:active {
    border-bottom: #eb5757 solid 2px;
    text-decoration: unset; }

.auth-page .auth-section .btn-primary {
  background-color: #eb5757 !important;
  border: 0 !important;
  font-size: 20px !important;
  min-height: 51px !important;
  min-width: 142px; }

.auth-page .footer {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0; }

@media screen and (max-width: 768px) {
  .auth-form {
    width: 100% !important; }
    .auth-form .btn-primary {
      margin-top: 3vh;
      width: 100% !important; } }

.forgot-password {
  height: 60vh; }
  .forgot-password p {
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    width: 40%;
    margin: auto; }
  .forgot-password .form-group {
    width: 45%; }

@media screen and (max-width: 768px) {
  .forgot-password h1 {
    margin-top: 1rem; }
  .forgot-password p {
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    width: 100%;
    margin: auto; }
  .forgot-password .auth-form .form-group {
    width: 100%;
    margin-bottom: 0; }
  .forgot-password .auth-form .btn-primary {
    margin-top: 0 !important; }
  .forgot-password .auth-form a {
    margin-top: 1rem; } }

.search-result-card {
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
  margin: auto;
  min-height: 263px;
  padding: 1.5%; }
  .search-result-card .company-overview a {
    text-decoration: none; }
  .search-result-card .company-overview h1 {
    color: #000;
    font-size: 32px;
    font-weight: 600; }
    .search-result-card .company-overview h1:hover {
      color: #00000085; }
  .search-result-card .company-overview .stars {
    color: #eb5757;
    font-size: 24px;
    font-weight: 900;
    line-height: 28px; }
  .search-result-card .company-overview .rating-count {
    color: #000;
    font-size: 24px;
    line-height: 39px; }
  .search-result-card .company-overview .review-count {
    color: #242222;
    font-size: 24px;
    line-height: 39px; }
  .search-result-card strong {
    color: #8d8d8d;
    font-size: 28px;
    font-weight: 600;
    line-height: 46px; }
    .search-result-card strong span {
      color: #eb5757; }
  .search-result-card button {
    background: #eb5757;
    border-radius: 4px;
    font-size: 18px;
    height: 49px;
    line-height: 30px;
    width: 174px; }
    .search-result-card button img {
      margin-top: 2px; }

@media screen and (max-width: 768px) {
  .search-result-card {
    padding: 5%; }
    .search-result-card .company-overview h1 {
      color: #000;
      font-size: 22px;
      font-weight: 600; }
    .search-result-card .company-overview .stars {
      font-size: 14px; }
    .search-result-card .company-overview .rating-count {
      font-size: 14px; }
    .search-result-card .company-overview .review-count {
      font-size: 14px; }
    .search-result-card strong {
      font-size: 18px; }
    .search-result-card .company-logo {
      height: 50px;
      margin-right: 15px;
      width: 50px; }
    .search-result-card .w-sm-80 {
      width: 80%; }
    .search-result-card .w-sm-100 {
      width: 100%; } }

a,
p,
div,
body {
  font-family: "Catamaran", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif; }

body {
  font-size: 16px; }

.btn-primary {
  color: #fff;
  background-color: #eb5757 !important;
  border-color: transparent !important;
  border-radius: 4px; }
  .btn-primary:hover {
    background-color: #c34848 !important;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out; }
  .btn-primary:disabled {
    background-color: #a17272 !important; }

.red-border-bottom {
  border-bottom: #eb5757 solid 1px; }

@media screen and (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important; } }

.search-result .result-container {
  width: 90%;
  margin: auto; }

.search-result .footer {
  margin-top: 3rem !important; }

a,
p,
div,
body {
  font-family: "Catamaran", sans-serif; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Lato", sans-serif; }

body {
  font-size: 16px; }

.btn-primary {
  color: #fff;
  background-color: #eb5757 !important;
  border-color: transparent !important;
  border-radius: 4px; }
  .btn-primary:hover {
    background-color: #c34848 !important;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out; }
  .btn-primary:disabled {
    background-color: #a17272 !important; }

.red-border-bottom {
  border-bottom: #eb5757 solid 1px; }

@media screen and (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important; } }

.footer {
  background-color: #fff;
  border: 1px solid #eb5757;
  font-size: 18px;
  height: 88px;
  line-height: 18px;
  text-align: center; }
  .footer .link {
    color: #000; }
  .footer p {
    margin-bottom: 0; }
  .footer .middle {
    font-size: 24px;
    font-weight: bold;
    line-height: 39px; }

@media screen and (max-width: 768px) {
  .footer {
    font-size: 10px;
    height: 50px; }
    .footer .middle {
      font-size: 10px;
      font-weight: bold; } }

.content .footer {
  margin-top: auto; }

