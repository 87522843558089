@import url('https://fonts.googleapis.com/css?family=Catamaran:400,700|Lato:400,700,900&display=swap');

// colors
$primary-white: #fff;
$primary-black: #000;
$primary-red: #eb5757;
$primary-grey: #c4c4c4;
$primary-blue: #14acf2;
$secondary-white: #f2f2f2;
$secondary-red: #a17272;

// fonts
$catamaran: 'Catamaran', sans-serif;
$lato: 'Lato', sans-serif;
