.search-result-card{
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.14);
  margin: auto;
  min-height: 263px;
  padding: 1.5%;

  .company-overview{
    a{
      text-decoration: none;
    }
    h1{
      color: #000;
      font-size: 32px;
      font-weight: 600;
      &:hover{
        color: #00000085;
      }
    }

    .stars{
      color: #eb5757;
      font-size: 24px;
      font-weight: 900;
      line-height: 28px;
    }

    .rating-count{
      color: #000;
      font-size: 24px;
      line-height: 39px;
    }

    .review-count{
      color: #242222;
      font-size: 24px;
      line-height: 39px;
    }
  }


  strong{
    color: #8d8d8d;
    font-size: 28px;
    font-weight: 600;
    line-height: 46px;

    span{
      color: #eb5757;
    }
  }

  button{
    background: #eb5757;
    border-radius: 4px;
    font-size: 18px;
    height: 49px;
    line-height: 30px;
    width: 174px;

    img{
      margin-top: 2px;
    }
  }
}

@media screen and (max-width: 768px) {
  .search-result-card{
    padding: 5%;

    .company-overview{
      h1{
        color: #000;
        font-size: 22px;
        font-weight: 600;
      }

      .stars{
        font-size: 14px;
      }

      .rating-count{
        font-size: 14px;
      }

      .review-count{
        font-size: 14px;
      }
    }

    strong{
     font-size: 18px;
    }

    .company-logo{
      height: 50px;
      margin-right: 15px;
      width: 50px;
    }
    .w-sm-80{
      width: 80%;
    }
    .w-sm-100{
      width: 100%;
    }
  }
}
